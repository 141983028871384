import { Route, HashRouter as Router, Switch } from 'react-router-dom'
import Footer from '../components/Footer/Footer'
import Header from '../components/Header/Header'
import Main from '../components/Main/Main'
import Menu from '../components/Menu/Menu.jsx'

export default function App () {
  return (
    <Router>
      <Header />
      <Switch>
        {/* <Route path='/cardapio'>
          <Cardapio />
        </Route> */}
        <Route path='/menu'>
          <Menu />
        </Route>
        <Route path='/'>
          <Main />
          <Footer />
        </Route>
      </Switch>
    </Router>
  )
}
