import styled, { keyframes } from 'styled-components'
/* eslint-disable import/no-anonymous-default-export */

const progress = keyframes`
  0%   {transform: scale(1);}
  100% {transform: scale(1.1);}
`

const Btn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 25rem;
  /* height: 7rem; */
  padding: 2rem;
  margin: 3rem auto 0;
  border: 3px solid #fff;
  border-radius: 2rem;
  font-size: 1.5rem;
  color: #fff;
  background: ${props => props.color};
  text-decoration: none;
  box-shadow: 0px 0px 10px 0px #020126;
  transition: all 0.7s;
  animation: ${progress} 1.5s alternate infinite ease-in;

  &:hover {
    background: ${props => props.background}!important;
    /* color: ${props => props.color}!important; */
    /* border-color: ${props => props.color}!important; */
  }

  @media (max-width: 770px) {
    font-size: 1.25rem;
    height: 6.5rem;
  }

  @media (max-width: 600px) {
    font-size: 1rem;
  }
`

export default { Btn }
