/* eslint-disable import/no-anonymous-default-export */

import styled from 'styled-components'

const ContainerWrapper = styled.main`
  width: 100%;
  background: #020126;
`

const ContainerLoader = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #eda407;
  display: flex;
  justify-content: center;
  align-items: center;
`

const toUp = styled.div`
  display: none;
  height: 3.5rem;
  width: 3.5rem;
  background-color: #01000f;
  bottom: 2rem;
  right: 1rem;
  position: fixed;
  border-radius: 50%;
  font-size: 3.5rem;
  color: white;
  z-index: 10;

  &:hover {
    color: #eee;
  }

  &.active {
    display: block;
    z-index: 10;
    cursor: pointer;
  }
`

const BoxTables = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: 770px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const Slogan = styled.div`
  margin: 9rem 0;

  h1 {
    color: white;
    font-family: 'The Nautigal', cursive;
    font-size: 10rem;
    font-style: italic;
    font-weight: 500;
    text-align: center;
    margin: 0 1rem 0 0;

    q {
      span {
        color: #eda407;
      }
    }

    @media (max-width: 957px) {
      font-size: 7.5rem;
    }

    @media (max-width: 700px) {
      font-size: 6.5rem;
      margin: 10.5rem 1rem 0 0;
    }

    @media (max-width: 550px) {
      font-size: 5.5rem;
      margin: 10.5rem 1rem 0 0;
    }
  }
`

const Container = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  /* padding: 2rem 1rem 2rem 1rem; */
  margin: 4rem 1rem 6rem;
  /* background: #ccc; */
  background-clip: content-box;

  @media (max-width: 957px) {
    &:nth-child(even) {
      h1 {
        line-height: 5rem;
      }
    }
  }

  @media (max-width: 770px) {
    &:nth-child(odd) {
      margin: 3rem 2rem 0rem 2rem;
    }

    &:nth-child(even) {
      margin: 4rem 2rem;
    }
  }
`

const Waves = styled.div`
  margin: 10rem auto;
  width: 100%;
`

const BoxTitle = styled.div`
  margin: 0 auto 4rem;
  max-width: 768px;

  h1 {
    flex: 1;
    text-align: center;
    color: #eda407;
    width: 100%;
    padding: 1rem;
    font-family: 'Euphoria Script', cursive;
    font-size: 4.25rem;
    font-weight: 400;
    letter-spacing: 0.125rem;
  }

  span {
    height: 0.25rem;
    width: 100%;
    display: block;
    background: #eda407;
  }

  @media (max-width: 768px) {
    margin: 0 auto 3rem;
  }
`

const Call = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 4rem 0;
  /* border-top: 0.2rem solid #eda407;
  border-bottom: 0.2rem solid #eda407; */

  p {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    color: #eda407;
    padding: 1rem;
    font-family: 'Euphoria Script', cursive;
    font-size: 4.5rem;
    width: 100%;

    @media (max-width: 1660px) {
      font-size: 4rem;
    }

    @media (max-width: 1500px) {
      font-size: 3.5rem;
    }

    @media (max-width: 1300px) {
      font-size: 3rem;
    }

    @media (max-width: 980px) {
      font-size: 2.5rem;
    }
  }
`

const ImageBg = styled.div`
  height: 1920px;
  width: 100%;
  padding: 2rem;

  div {
    background: url(${props => props.src});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    filter: saturate(1.1) drop-shadow(0px 10px 100px rgba(0 0 0 / 85%));
  }

  @media (max-width: 1081px) {
    /* height: 600px; */
  }

  @media (max-width: 981px) {
    height: 1480px;
  }

  @media (max-width: 599px) {
    height: 1250px;
  }

  @
`

const RodizioContainer = styled.section`
  margin: 0 auto 8rem;
  /* max-width: 1280px; */
  width: 100%;
  text-align: center;

  h1 {
    font-size: 3.5rem;
    color: #eda407;
    width: 100%;
    padding: 1rem;
    font-family: 'Euphoria Script', cursive;
  }

  & .schedule {
    margin: 0 0 2rem 0;
    color: #eda407;

    & .schedule__title {
      font-size: 2.25rem;
      font-style: italic;
    }

    & .schedule__divisory {
      display: flex;
      justify-content: center;
      align-items: center;

      & .schedule__bullet {
        width: 2rem;
        text-align: center;

        span {
          height: 0.75rem;
          width: 0.75rem;
          margin-right: 0.25rem;
          border-radius: 50%;
          display: inline-block;
          background: #eda407;
        }
      }

      & .schedule__text {
        width: max-content;
        min-width: 15rem;
        text-align: left;
        font-size: 1.75rem;
        font-weight: 300;
      }
    }
  }

  .options_rodizio {
    margin: 4rem 0 2rem;
    color: #eda407;

    h3 {
      width: 100%;
      font-size: 4.5rem;
      font-weight: 400;
      letter-spacing: 0.2rem;
      font-family: 'Euphoria Script', cursive;
      text-align: center;
      /* width: fit-content; */
      margin: 6rem auto 2rem;
      letter-spacing: 0.125rem;
      padding: 0.5rem;
      background: #01000f;
      /* border-bottom: 0.25rem solid #eda407; */
    }

    & .options_rodizio_wrapper {
      max-width: 1280px;
      margin: 0 auto;
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;

      @media (max-width: 770px) {
        flex-direction: column;

        & .options_rodizio_list {
          width: 25rem;
          align-self: center;
        }
      }

      & .options_rodizio_section {
        display: flex;
        flex-direction: column;
        margin: 0 1rem;
        flex: 1 1 0;

        h4 {
          display: flex;
          align-items: center;
          height: 5rem;
          margin: 1rem auto;
          font-size: 1.75rem;
          letter-spacing: 0.05rem;
          font-style: italic;
          border-bottom: 0.15rem solid #eda407;
        }

        ul {
          text-align: left;
          margin: 0.5rem 1.5rem;
          list-style-type: none;
          font-size: 1.25rem;
          font-weight: 400;
          min-width: 300px;

          li {
            margin: 0.2rem 0;
            background: #020126;
            padding: 0.5rem;
            /* text-indent: 1rem; */
          }
        }
      }
    }
  }
`

const GalleryPhotos = styled.section`
  display: flex;
  align-self: center;
  justify-content: space-around;
  max-width: 1280px;
  width: 100%;
  flex-wrap: wrap;
  margin: 1.75rem 0 0 0;

  &:nth-of-type(1) {
    margin: 4rem 0 0 0;
  }

  @media (max-width: 770px) {
    max-width: 98%;
    margin: 0;
    flex-direction: column;
  }
`

const Image = styled.div`
  width: 49%;
  height: 480px;
  overflow: hidden;
  border-radius: 1rem 10%;
  box-shadow: 0px 0px 5px 0px #eda407;
  border: 0.2rem solid #eda407;
  margin: 0 auto;

  @media (max-width: 1280px) {
    height: 470px;
  }
  @media (max-width: 1180px) {
    height: 450px;
  }
  @media (max-width: 1080px) {
    height: 425px;
  }
  @media (max-width: 980px) {
    height: 375px;
  }
  @media (max-width: 950px) {
    height: 355px;
  }
  @media (max-width: 890px) {
    height: 335px;
  }
  @media (max-width: 850px) {
    height: 315px;
  }
  @media (max-width: 790px) {
    height: 300px;
  }
  @media (max-width: 770px) {
    width: 99%;
    height: auto;
    border: 0.1rem solid #eda407;
  }

  img {
    height: 100%;
    width: 100%;
    transition: transform 0.5s;
    transform: scale(1.02);
    filter: saturate(110%);
    object-fit: cover;
    position: sticky;

    &:hover {
      transform: scale(1.15);
      filter: brightness(85%);
    }

    @media (max-width: 770px) {
      transform: scale(1.2);
    }
  }
`

const ContainerBtn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
  margin: 2rem auto 0rem;
  background-clip: content-box;

  @media (max-width: 770px) {
    width: 80%;

    a {
      font-size: 1.5rem;
    }
  }
`

export default {
  ContainerWrapper,
  ContainerLoader,
  toUp,
  BoxTables,
  Container,
  Waves,
  BoxTitle,
  Slogan,
  Call,
  RodizioContainer,
  ImageBg,
  GalleryPhotos,
  Image,
  ContainerBtn
}
