import S from './index'

const Button = ({ termo, goTo, color, background }) => (
  <S.Btn
    href={goTo}
    color={color}
    background={background}
    target='_blank'
    type='application/pdf'
  >
    {termo}
  </S.Btn>
)

export default Button
