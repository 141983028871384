import React from 'react'
import { Link } from 'react-router-dom'
import LogoAzul from '../../assets/images/LOGO_PERFIL_FUNDO1.svg'
import S from './index'

function Header () {
  function handleToggleMenu () {
    const nav_mobile = document.querySelector('.nav_mobile')
    const hamburger = document.querySelector('#hamburger')

    if (!hamburger?.classList.contains('active')) {
      nav_mobile?.classList.add('active')
      hamburger?.classList.add('active')
    } else {
      nav_mobile?.classList.remove('active')
      hamburger?.classList.remove('active')
    }
  }

  function handleOptions () {
    handleToggleMenu()
  }

  return (
    <S.Container>
      <S.Logo>
        <Link to='./'>
          <img
            src={LogoAzul}
            className='yellow'
            alt='Logo do Mar Dourado Sushi com detalhes em branco e amarelo'
          />
        </Link>
        <div
          id='hamburger'
          onClick={handleToggleMenu}
          // style={{ display: `${visualization}` }}
        >
          <span></span>
          <span></span>
          <span></span>
        </div>
      </S.Logo>

      <S.NavPc>
        <ul>
          <a href='./#sushi'>
            <li>Culinária Japonesa</li>
          </a>

          <a href='./#contato'>
            <li>Contato</li>
          </a>

          <a href='./#menu'>
            <li>Menu</li>
          </a>
        </ul>
      </S.NavPc>

      <S.NavMobile>
        <ul className='nav_mobile'>
          <a href='./#sushi'>
            <li onClick={handleOptions}>Culinária Japonesa</li>
          </a>

          <a href='./#contato'>
            <li onClick={handleOptions}>Contato</li>
          </a>

          <a href='./#menu'>
            <li onClick={handleOptions}>Menu</li>
          </a>
        </ul>
      </S.NavMobile>
    </S.Container>
  )
}

export default Header
