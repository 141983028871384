import styled from 'styled-components'
/* eslint-disable import/no-anonymous-default-export */

const Container = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 13rem;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  background: #020126;
  flex: 1;

  @media (max-width: 980px) {
    flex-direction: column;
  }
`

const Logo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  img {
    width: 12rem;
    object-fit: cover;

    @media (max-width: 980px) {
      width: 10rem;
    }
  }

  #hamburger {
    width: 50px;
    height: 50px;
    margin-right: 0.5rem;
    display: none;
    cursor: pointer;

    @media (max-width: 980px) {
      display: flex;
      align-items: center;
    }

    span {
      width: 45px;
      height: 4px;
      background-color: white;
      position: absolute;
      border-radius: 0.25rem;

      &:nth-child(1) {
        transform: translateY(-15px);
        transition: transform 0.3s;
      }
      &:nth-child(2) {
        transform: translateY(0px);
        transition: background 0.1s;
      }

      &:nth-child(3) {
        transform: translateY(15px);
        transition: transform 0.3s;
      }
    }

    &.active span {
      &:nth-child(1) {
        transform: translateY(0px) rotate(-45deg);
      }

      &:nth-child(2) {
        background: transparent;
      }

      &:nth-child(3) {
        transform: translateY(0px) rotate(45deg);
      }
    }
  }
`

const NavPc = styled.nav`
  margin: 0 1rem;

  ul {
    list-style-type: none;
    display: flex;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1.5rem;
      width: 10rem;
      text-align: center;
      margin: 0 0.25rem;
      transform: skew(-20deg);
      text-decoration: none;
      color: #fff;
      background: #eda407;
      font-size: 1.5rem;

      &:hover {
        background: #fff;
        color: #eda407;
        transition: all 0.3s ease-in;
      }
    }
  }

  @media (max-width: 980px) {
    margin: 0;
    display: none;
  }
`

const NavMobile = styled.nav`
  margin: 0 1rem;
  display: none;

  .nav_mobile {
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-evenly;
    opacity: 0;
    transition: transform 0.3s;
    pointer-events: none;
    padding: 0;
    transition: all 0.2s linear;

    &.active {
      opacity: 1;
      transform: translateY(0);
      pointer-events: visible;
      margin-top: 6rem;
      width: 100%;
      background: #020126;
    }

    a {
      margin: 0 0.25rem;
      transform: skew(-20deg);
      text-decoration: none;
      color: #fff;
      background: #eda407;
      font-size: 1.5rem;
      text-align: center;

      li {
        padding: 2.25rem;
      }

      @media (max-width: 980px) {
        margin: 0.25rem;
        font-size: 2rem;
      }

      &:hover {
        background: #fff;
        color: #eda407;
        transition: all 0.5s ease-in-out;
      }
    }
  }

  @media (max-width: 980px) {
    display: flex;
    width: 100%;
  }
`

export default { Container, Logo, NavPc, NavMobile }
