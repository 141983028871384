import React from 'react'
import { FaFacebookSquare, FaMapMarkerAlt, FaMobile } from 'react-icons/fa'
import { RiInstagramFill, RiPhoneFill } from 'react-icons/ri'
import S from './index'

const date = new Date()
const year = date.getFullYear()
const copy = '\u2117'

const Footer = () => {
  return (
    <S.ContainerFoot id='contato'>
      <S.ContainerWrapper>
        <iframe
          src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3673.5593289727717!2d-47.002839884486825!3d-22.96645084577776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c8cdc7a9fabe4b%3A0xf75144030cf42ca8!2sEmp%C3%B3rio%20Mar%20Dourado!5e0!3m2!1spt-BR!2sbr!4v1633108870737!5m2!1spt-BR!2sbr'
          width='80%'
          height='500'
          style={{ border: 0 }}
          allowFullScreen={true}
          loading='lazy'
          title='mapa'
        ></iframe>

        <h4>
          Nos acompanhe em nossas <span>redes sociais</span>:
        </h4>

        <div>
          <address>
            <p>
              <S.Access
                href='https://www.facebook.com/mardouradosushi'
                target='_blank'
                rel='noreferrer'
              >
                <FaFacebookSquare /> <span>/mardouradosushi</span>
              </S.Access>
            </p>

            <p>
              <S.Access
                href='https://www.instagram.com/mardouradosushi'
                target='_blank'
                rel='noreferrer'
              >
                <RiInstagramFill /> <span>/mardouradosushi</span>
              </S.Access>
            </p>

            <p>
              <S.Access
                href='https://goo.gl/maps/zPPVjEtr5aWQwQPt8'
                target='_blank'
                rel='noreferrer'
              >
                <FaMapMarkerAlt />
                <span>
                  Rua Campos Salles, 395 <br /> Vila São Sebastião <br />
                  CEP: 13272-477 | Valinhos/SP
                </span>
              </S.Access>
            </p>

            <p>
              <S.Access href='tel:19 3244-2645'>
                <RiPhoneFill /> <small>(19)</small> <span>3244-2645</span>
              </S.Access>

              <S.Access
                href='https://wa.me/message/VQLC3RWZD2W3B1'
                target='_blank'
                rel='noreferrer'
              >
                <FaMobile />
                <small>(19)</small> <span>99920-0266</span>
              </S.Access>
            </p>
          </address>
        </div>

        <S.CopyRight>
          <p>
            Empório Mar Dourado - Todos os direitos reservados{copy} {year}
          </p>
        </S.CopyRight>
      </S.ContainerWrapper>
    </S.ContainerFoot>
  )
}

export default Footer
