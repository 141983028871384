/* eslint-disable import/no-anonymous-default-export */

import styled from 'styled-components'

const MenuContainer = styled.main`
  max-width: 1280px;
  height: 60vh;
  margin: 4rem auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`

export default { MenuContainer }
