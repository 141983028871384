import ButtonMenu from '../ButtonMenu/ButtonMenu'
import S from './index'

function Menu () {
  return (
    <S.MenuContainer>
      <ButtonMenu
        termo='Cardápio Culinária Japonesa'
        goTo='https://emporiomardourado.com.br/cardapio.pdf'
      />

      {/* <ButtonMenu
        termo='Cardápio Culinária Brasileira'
        goTo='https://emporiomardourado.com.br/cardapio-restaurante.pdf'
      /> */}

      <ButtonMenu
        termo='Tabela de Preços Empório'
        goTo='https://emporiomardourado.com.br/tabela-de-precos.pdf'
      />
    </S.MenuContainer>
  )
}

export default Menu
