import styled from 'styled-components'
/* eslint-disable import/no-anonymous-default-export */

const ContainerFoot = styled.footer`
  border-top: 2px solid white;
  width: 100%;
  background: #eda407;
  align-self: flex-end;
`

const ContainerWrapper = styled.section`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  iframe {
    width: 100%;

    @media (max-width: 1280px) {
      height: 400px;
      width: 100%;
    }
  }

  h4 {
    margin: 1rem 0 0 0;
    text-align: center;
    background: none;
    font-size: 2.5rem;
    color: #020126;
    font-weight: 400;
    /* border-top: 0.25rem solid #020126; */

    span {
      font-weight: 600;
      text-decoration: underline #020126;
    }

    @media (max-width: 768px) {
      max-width: 400px;
    }
  }

  div {
    padding: 1rem;
    display: flex;
    flex-direction: column;

    @media (max-width: 768px) {
      flex-direction: column;
      width: 100%;
    }
  }

  address {
    display: flex;
    flex-direction: column;

    p {
      width: 100%;
      margin: 0.25rem;
      color: #020126;
      display: flex;
      align-items: flex-start;

      a {
        display: flex;
        cursor: default;
      }

      small {
        font-size: 1.25rem;
        margin: 0 0.25rem;
      }
    }

    @media (max-width: 770px) {
      align-self: center;

      p:last-child {
        flex-direction: column;
      }
    }
  }
`

const Access = styled.a`
  color: #020126;
  font-size: 4rem;
  background: none;
  text-decoration: none;
  margin: 0 1rem;
  transition: color 0.3s;
  cursor: pointer !important;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff;
  }

  span {
    margin-left: 0.5rem;
    font-size: 1.5rem;

    @media (max-width: 770px) {
      font-size: 1.75rem;
    }

    @media (max-width: 550px) {
      font-size: 1.5rem;
    }

    @media (max-width: 350px) {
      font-size: 1.4rem;
    }
  }
`

const CopyRight = styled.div`
  width: 100%;
  text-align: center;
  background: #020126;
  color: #fff;
  font-size: 1.1rem;
  margin-top: 0.25rem;
`

export default { ContainerFoot, ContainerWrapper, Access, CopyRight }
