import { useEffect, useState } from 'react'
import { FaArrowAltCircleUp } from 'react-icons/fa'
import Loader from 'react-loader-spinner'
import combo from '../../assets/images/sushi/combinado-rodizio.png'
// import barca from '../../assets/images/sushi/IMG_8682.jpg'
import marinado_salmao from '../../assets/images/sushi/IMG_8605.jpg'
import carpaccio_salmao from '../../assets/images/sushi/IMG_8747.jpg'
import ceviche_tilapia from '../../assets/images/sushi/IMG_8926.jpg'
import temaki from '../../assets/images/sushi/IMG_8944.jpg'
import shimeji from '../../assets/images/sushi/IMG_8969.jpg'
import tartar_salmao from '../../assets/images/sushi/IMG_8994.jpg'
import sashimi from '../../assets/images/sushi/IMG_9097.jpg'
import hotRoll from '../../assets/images/sushi/IMG_9119.jpg'
import waves from '../../assets/images/waves-emporio.svg'
import Button from '../Button/Button'
import S from './index'

import 'swiper/css'
import 'swiper/css/autoplay'
import 'swiper/css/effect-fade'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

function Main () {
  const [load, setLoad] = useState(true)

  // useEffect(() => {
  //   window.addEventListener('load', () => {
  //     setLoad(false)
  //   })
  // }, [load])

  setInterval(() => {
    setLoad(false)
  }, 3000)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const toUp = document.querySelector('.toUp')

      if (window.scrollY > 250) {
        toUp?.classList.add('active')
      } else {
        toUp?.classList.remove('active')
      }
    })
  }, [])

  function goToUp () {
    window.scrollTo(0, 0)
  }

  return (
    <>
      <S.toUp className='toUp' onClick={goToUp}>
        <FaArrowAltCircleUp />
      </S.toUp>

      {load ? (
        <S.ContainerLoader>
          <Loader
            type='ThreeDots'
            color='#020126'
            height={100}
            width={100}
            timeout={3000} //3 secs
          />
        </S.ContainerLoader>
      ) : (
        <S.ContainerWrapper>
          <S.BoxTables>
            <S.Slogan>
              <h1>
                <q>
                  O <span>Melhor</span> dos <br /> <span>Mares </span>
                  para <span>Você!</span>
                </q>
              </h1>
            </S.Slogan>

            <S.Container>
              <S.BoxTitle>
                <span></span>
                <h1>Culinária Japonesa</h1>
                <span></span>
              </S.BoxTitle>

              <S.Call>
                <S.ImageBg src={combo}>
                  <div
                    alt='Foto de um combinado de sushi'
                    loading='lazy'
                    id='sushi'
                  ></div>
                </S.ImageBg>

                {/* <S.ImageBg src={combo}>
                  <div
                    alt='Foto de um combinado de sushi'
                    loading='lazy'
                    id='sushi'
                  ></div>
                </S.ImageBg> */}
              </S.Call>

              <S.BoxTitle>
                <span></span>
                <h1>Rodízio Japonês</h1>
                <span></span>
              </S.BoxTitle>

              <S.RodizioContainer>
                <div className='schedule'>
                  <div className='schedule__title'>
                    De segunda a domingo (Almoço):
                  </div>
                  <div className='schedule__divisory'>
                    <p className='schedule__bullet'>
                      <span></span>
                    </p>
                    <p className='schedule__text'> das 11:30 às 15:00</p>
                  </div>
                </div>

                <div className='schedule'>
                  <div className='schedule__title'>
                    De segunda a sábado (Jantar):
                  </div>
                  <div className='schedule__divisory'>
                    <p className='schedule__bullet'>
                      <span></span>
                    </p>
                    <p className='schedule__text'>das 18:30 às 22:00</p>
                  </div>
                </div>

                <div className='options_rodizio'>
                  <h3>O que servimos:</h3>
                  <div className='options_rodizio_wrapper'>
                    <div className='options_rodizio_section'>
                      <h4>Mais de 15 opções de Entradas</h4>
                      <div className='options_rodizio_list'>
                        <ul>
                          <li>Sunomono</li>
                          <li>Shimeji</li>
                          <li>Carpaccio de Salmão</li>
                          <li>Carpaccio de Tilápia</li>
                          <li>Ceviche de Tilápia</li>
                          <li>Ceviche de Salmão</li>
                          <li>Kani Kama Especial</li>
                          <li>Bolinho de Salmão</li>
                          <li>Guioza</li>
                          <li>Harumaki de Queijo</li>
                          <li>Harumaki de Legumes</li>
                          <li>Lula à dorê</li>
                          <li>Isca de Peixe</li>
                          <li>Pop Ten (Camarão Empanado)</li>
                          <li>
                            Salmon Frie Cheese <br /> (Especialidade da Casa)
                          </li>
                          <li>+ de 6 opções de Temaki</li>
                          <li>Teppan de Salmão</li>
                        </ul>
                      </div>
                    </div>

                    <div className='options_rodizio_section'>
                      <h4>Barca Principal</h4>
                      <div className='options_rodizio_list'>
                        <ul>
                          <li>Hossomaki de Atum</li>
                          <li>Hossomaki de Salmão</li>
                          <li>Joy de Salmão</li>
                          <li>Niguiri de Atum</li>
                          <li>Niguiri de Salmão</li>
                          <li>Sashimi de Atum</li>
                          <li>Sashimi de Salmão</li>
                          <li>Sashimi de Tilápia</li>
                          <li>Uramaki de Atum</li>
                          <li>Uramaki de Salmão</li>
                          <li>Peças Maçaricadas</li>
                          <li>Peças com geleias e molhos</li>
                        </ul>
                      </div>
                    </div>

                    <div className='options_rodizio_section'>
                      <h4>Sushis Doces</h4>
                      <div className='options_rodizio_list'>
                        <ul>
                          <li>Hot Banana com Nutella</li>
                          <li>Hot Banana com Leite Condensado</li>
                          <li>Joy Romeu e Julieta</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </S.RodizioContainer>

              <S.BoxTitle>
                <span></span>
                <h1>Menu À La Carte</h1>
                <span></span>
              </S.BoxTitle>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={carpaccio_salmao}
                    alt='Foto de um delicioso carpaccio de salmão'
                    loading='lazy'
                  />
                </S.Image>

                <S.Image>
                  <img
                    src={ceviche_tilapia}
                    alt='Foto de um ceviche de tilápia'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={shimeji}
                    alt='Foto de 2 cumbucas de shimeji cozidas e temperadas'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={tartar_salmao}
                    alt='Foto de um delicioso tartar de salmão'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={sashimi}
                    alt='Foto de sashimis de salmão, atum e tilápia'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={hotRoll}
                    alt='Foto de vários hot roll'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={marinado_salmao}
                    alt='Foto de um delicioso marinado de salmão'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={temaki}
                    alt='Foto de dois temakis de salmão grelhado despojados um contra o outro'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.ContainerBtn>
                <Button
                  termo='Acessar Cardápio'
                  goTo='https://emporiomardourado.com.br/cardapio.pdf'
                  color='#ffaf00'
                  background='#f75f05'
                />
              </S.ContainerBtn>
            </S.Container>

            {/* <S.Waves>
              <img
                src={waves}
                alt='Ondas do logo do Empório Mar Dourado na cor amarela'
              />
            </S.Waves> */}

            {/* <S.Container>
              <S.BoxTitle>
                <span></span>
                <h1>Culinária Brasileira</h1>
                <span></span>
              </S.BoxTitle>

              <S.Call>
                <S.ImageBg src={camaraoMarDourado}>
                  <div
                    alt='Foto de camarões limpos e grandes ao lado de ervas e um limão cortado'
                    loading='lazy'
                    id='restaurante'
                  ></div>
                </S.ImageBg>
              </S.Call>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={tilapiaGrelhada}
                    alt='Foto de uma bonita tilápia grelhada acompanhada de brócolis, mandioquinha, cenoura e arroz branco'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={pintadoGrelhado}
                    alt='Foto 2 caudas de lagostas assadas, cobertas de queijo mussarela e gorgonzola, acompanhada de purê de mandioquinha e brócolis'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={abadejoCamarao}
                    alt='Foto de abadejo assado com molho de camarão, acompanhado de brócolis, mandioquinha, cenoura e arroz branco'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={truta}
                    alt='Foto de truta arco-íris com laminas de amendôas, acompanhada de brócolis, mandioquinha, cenoura, purê de mandioquinha e arroz branco'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={bacalhau}
                    alt='Foto de bacalhau ao forno acompanhado de batata sautê, brócolis, pimentões, azeitonas, ovo cozido e arroz branco'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={dourada}
                    alt='Foto de dourada grelhada acompanhada de creme de limão, purê de mandioquinha, brócolis, mandioquinha, cenoura e arroz branco'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={parmegiana}
                    alt='Foto de parmegiana de peixe, acompanhada de molho de tomate caseiro, batata-frita e arroz branco'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={camarãoPorção}
                    alt='Foto de camarões grelhado no alho e óleo acompanhado de uma folha de alface e molho tártaro'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.ContainerBtn>
                <Button
                  termo='Acessar Cardápio'
                  goTo='https://www.emporiomardourado.com.br/cardapio-restaurante.pdf'
                  color='#f75f05'
                  background='#ba0000'
                />
              </S.ContainerBtn>
            </S.Container> */}

            {/* <S.Waves>
              <img
                src={waves}
                alt='Ondas do logo do Mar Dourado Sushi na cor amarela'
              />
            </S.Waves> */}

            {/* <S.Container>
              <S.BoxTitle>
                <span></span>
                <h1>Linha do Empório</h1>
                <span></span>
              </S.BoxTitle>

              <S.Call>
                <S.ImageBg src={camarao}>
                  <div
                    alt='Foto de camarões limpos e grandes ao lado de ervas e um
                    limão cortado'
                    loading='lazy'
                    id='emporio'
                  ></div>
                </S.ImageBg>
              </S.Call>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={polvo}
                    alt='Foto de um polvo nacional ao lado de ervas e um limão cortado'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={vieiras}
                    alt='Foto de vieiras com cauda ao lado de ervas e um limão cortado'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={lagosta}
                    alt='Foto de cauda de lagosta ao lado de ervas e um limão cortado'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={camaraoInteiro}
                    alt='Foto de camarões inteiros grandes ao lado de ervas e um limão cortado'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={lula}
                    alt='Foto de lula em anéis ao lado de ervas e um limão cortado'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={tilapia}
                    alt='Foto de um filé de tilápia ao lado de uma faca culinária, ervas e um limão cortado'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.GalleryPhotos>
                <S.Image>
                  <img
                    src={salmao}
                    alt='Foto de um salmão fresco ao lado de uma faca culinária, ervas e um limão cortado'
                    loading='lazy'
                  />
                </S.Image>
                <S.Image>
                  <img
                    src={pintado}
                    alt='Foto de postas de pintado ao lado de ervas e um limão cortado'
                    loading='lazy'
                  />
                </S.Image>
              </S.GalleryPhotos>

              <S.ContainerBtn>
                <Button
                  termo='Acessar Tabela de Preços'
                  goTo='https://www.emporiomardourado.com.br/tabela-de-precos.pdf'
                  color='#ffaf00'
                  background='#f75f05'
                />
              </S.ContainerBtn>
            </S.Container> */}

            <S.Waves>
              <img
                src={waves}
                alt='Ondas do logo do Empório Mar Dourado na cor amarela'
              />
            </S.Waves>

            <S.Container>
              <S.BoxTitle>
                <span></span>
                <h1>Contato</h1>
                <span></span>
              </S.BoxTitle>
            </S.Container>
          </S.BoxTables>
        </S.ContainerWrapper>
      )}
    </>
  )
}

export default Main
