import styled, { keyframes } from 'styled-components'
/* eslint-disable import/no-anonymous-default-export */

const progress = keyframes`
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 400%;
  }
`

const Btn = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 8rem;
  width: 25rem;
  padding: 2rem;
  margin: 2rem auto;
  border: 3px solid #fff;
  border-radius: 2rem;
  font-size: 1.75rem;
  color: #fff;
  /* background: ${props => props.color}; */
  background: linear-gradient(90deg, #eda407, orange, red, #eda407);
  background-size: 400% auto;
  /* background-image: linear-gradient(to right, #f6d365 0%, #fda085 51%, #f6d365 100%); */
  text-decoration: none;
  box-shadow: 0px 0px 10px 0px #fff;
  /* transition: all 0.7s; */

  &:hover {
    animation: ${progress} 9s linear infinite;
  }

  a:before {
    content: '';
    background: linear-gradient(45deg, #f15523, #ef3224, #7c3697);
    background-size: 400% auto;
    border-radius: 2.25rem;
    opacity: 0;
    transition: 0.5%;
  }

  a:hover:before {
    filter: blur(20px);
    opacity: 1;
    animation: ${progress} 9s linear infinite;
  }
`

export default { Btn }
